import Swal from "sweetalert2";
import axiosBase from 'axios';

export const TOKEN_KEY_STORE = "access_token";
export const STORE = 2;
export const ADMIN = 1;
export const APPLICATION = 3;
export const APPLICATION_STEPS = 4;
export const APPLICATION_COMPLETE = 5;
export const APPLICATION_CANCEL = 6;
export const APPLICATION_ACCEPTED_CORRECTIONS = 7;
export const APPLICATION_REJECTED = 8;
export const NEW_ACCOUNT = 9;
export const ROLE_MEMBER = "ROLE_MEMBER";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const RESET_STORE = "RESET_STORE";
export const SITEKEY='6LdOFuEkAAAAAB5kBCImDXqwOOkuqaWHbq4g1E5C';

export const FEATURE_TYPES = {
    STEP_1: 1,
    STEP_2: 2,
    STEP_3: 3,
    STEP_4: 4,
    STEP_5: 5,
    STEP_6: 6,
    STEP_7: 7,
};

export const merchantCore = {
    agxMerchantNo: null,
    agxMerchantid: '',
    agxContactId: '',
    agxBusinessType: 283260006,
    agxBusinessForm: 283260000,
    agxMedicalInstitutionCode: '',
    agxEmsEmployeeNo: '',
    agxCorporateName: '',
    agxCorporatePhoneticName: '',
    agxCorporateEnglishName: '',
    agxCorporateNumber: '',
    agxCorporatePostalCode: '',
    agxCorporatePrefecture: '', //北海道
    agxCorporateAddress1: '',
    agxCorporateAddress2: '',
    agxCorporatePhoneticPrefecture: '',
    agxCorporatePhoneticAddress1: '',
    agxCorporatePhoneticAddress2: '',
    agxCorporatePhoneNumber: '',
    agxCorporateFaxNumber: '',
    agxRepresentativeName: '',
    agxRepresentativePhoneticName: '',
    agxRepresentativeGender: 283260000,
    agxRepresentativeBirthday: '1973-01-01',
    agxRepresentativeAddressCopyFlag: false,
    agxRepresentativePostalCode: '',
    agxRepresentativePrefecture: '', //北海道
    agxRepresentativeAddress1: '',
    agxRepresentativeAddress2: '',
    agxRepresentativePhoneticPrefecture: '',
    agxRepresentativePhoneticAddress1: '',
    agxRepresentativePhoneticAddress2: '',
    agxRepresentativePhoneNumber: '',
    agxRepresentativeFaxNumber: '',
    agxStoreName: '',
    agxStorePhoneticName: '',
    agxStoreEnglishName: '',
    agxUrl: '',
    agxBrandName: '',
    agxBusinessDate: '',
    agxRegularHoliday: '',
    agxBusinesssHours: '',
    agxStoreAddressCopyFlag1: false,
    agxStoreAddressCopyFlag2: false,
    agxStorePostalCode: '',
    agxStorePrefecture: '',
    agxStoreAddress1: '',
    agxStoreAddress2: '',
    agxStorePhoneticPrefecture: '',
    agxStorePhoneticAddress1: '',
    agxStorePhoneticAddress2: '',
    agxStorePhoneNumber: '',
    agxStoreFaxNumber: '',
    agxBankNo: '',
    agxBankName: '',
    agxBankType: 283260000,
    agxBranchNo: '',
    agxBranchName: '',
    agxBranchType: 283260000,
    agxBankPhoneticName: '',
    agxBranchPhoneticName: '',
    agxAccountType: 283260000,
    agxAccountNo: '',
    agxAccountHolder: '',
    agxContactName: '',
    agxContactEmail: '',
    agxContactPhoneticName: '',
    agxContactPhoneNumber: '',
    agxCapital: 0,
    agxNumberOfEmployees: '',
    agxFoundingDate: '1973-01-01',
    agxMonthlySales: 0,
    agxDoorToDoorSales: false,
    agxTelemarketingSales: false,
    agxPyramidScheme: false,
    agxBusinessOpportunityRelatedSales: false,
    agxSpecifiedContinuousServices: false,
    agxCardInformationRetentionStatus: 283260001,
    agxNoRetainingCardInfoDate: null,
    agxPcidssStatus: 283260001,
    agxPcidssExpectedComplianceDate: null,
    agxThreeDSecureStatus: 283260001,
    agxThreeDSecureDate: null,
    agxSecurityCodeCheckStatus: 283260001,
    agxSecurityCodeCheckDate: null,
    agxIllegalDeliveryDestinationStatus: 283260001,
    agxIllegalDeliveryDestinationDate: null,
    agxBehaviorAnalysisStatus: 283260001,
    agxBehaviorAnalysisDate: null,
    agxOtherMeasuresStatus: 283260001,
    agxOtherMeasuresDate: null,
    agxOtherMeasuresDescription: '',
    agxNumberOfTerminal: 0,
    agxColorOfTerminal: 283260000,
    agxSettlementCard: true,
    agxSettlementJcb: true,
    agxSettlementTraffic: false,
    agxSettlementNanaco: false,
    agxSettlementWaon: false,
    agxSettlementEdy: false,
    agxSettlementAid: false,
    agxSettlementQuicpay: false,
    agxSettlementQrCode: false,
    agxApplicationStatus: null,
    // agxMunMerchantNo: '',
    // agxStoreBranchNo: '',
    // agxJcbExistsMembershipFlag: null,
    // agxJcbMerchantNumber: ''
};

export const convertDate = (value) => {
    const result = [];
    if (value && value.length > 0) {
        const arraySplit = value.split("-");
        result.push(arraySplit[0]);
        result.push(arraySplit[1].startsWith('0') ? arraySplit[1].substring(1, 2) : arraySplit[1]);
        // result.push(arraySplit.length === 3 ? arraySplit[2].substring(0, 2) : '');
        if(arraySplit.length === 3){
            result.push(arraySplit[2].startsWith('0') ? arraySplit[2].substring(1, 2) : arraySplit[2]);
        }else{
            result.push('');
        }
    } else {
        result.push('');
        result.push('');
        result.push('');
    }
    return result;
}
export const convertPhoneAndFax = (value) => {
    const result = [];
    if (value && value.length > 0) {
        const arraySplit = value.split("-");
        result.push(arraySplit[0]);
        result.push(arraySplit[1]);
        result.push(arraySplit[2]);
    } else {
        result.push('');
        result.push('');
        result.push('');
    }
    return result;
}
export const mapAccountType = new Map([[283260000, "普通預金"], [283260001, "当座預金"]]);
export const mapBusinessType = new Map([[283260000, "クリニック"], [283260001, "薬局"], [283260002, "歯科"], [283260003, "病院（20-199床）"], [283260004, "病院（200床以上）"], [283260005, "薬局（年商100億円以上）"], [283260006, "B2B"]]);
export const mapBusinessForm = new Map([[283260000, "法人"], [283260001, "個人"]]);
export const mapTransactionType = new Map([
    [283260000, "クレジットカード一括"]
    , [283260001, "クレジットカードボーナス"]
    , [283260002, "クレジットカード分割"]
    , [283260003, "クレジットカードリボ"]
    , [283260004, "銀聯"]
    , [283260005, "交通系電子マネー"]
    , [283260006, "WAON"]
    , [283260007, "nanaco"]
    , [283260008, "Edy"]
    , [283260009, "iD"]
    , [283260010, "PayPay"]
    , [283260011, "メルペイ"]
    , [283260012, "auPAY"]
    , [283260013, "LinePay"]
    , [283260014, "d払い"]
    , [283260015, "アリペイ"]
    , [283260016, "WeChatPay"]
    , [283260017, "BankPay"]
    , [283260018, "クレジットカード一括(JCB)"]
    , [283260019, "クレジットカード2回払(JCB)"]
    , [283260020, "クレジットカードボーナス(JCB)"]
    , [283260021, "クレジットカード分割(JCB)"]
    , [283260022, "クレジットカードリボ(JCB)"]
    , [283260023, "JCBプレモ"]
    , [283260024, "QUICPay"]
]);
export const mapRepresentativeGender = new Map([[283260000, "男性"], [283260001, "女性"], [283260002, "不明"]]);

export const formatDateJapan = (value) => {
    if ( value && value.length > 0) {
        let dates = value.split('-');
        let result = dates[0] + '年' + dates[1] + '月' + (dates[2].length > 2 ? dates[2].substring(0, 2) + '日' : `${dates[2]}日`);
        return result;
    } else {
        return '';
    }
}

export const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

export const axiosPDF = axiosBase.create({
    baseURL: '/',
    headers: {
        'Content-Type': 'application/x-font-ttf',
    },
    responseType: 'blob',
})

export const formatDate = (date) => {
    let day = date.getDate().toString().length < 2 ? ('0' + date.getDate()) : date.getDate();
    let month = (date.getMonth() + 1).toString().length < 2 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let result = year + "-" + month + "-" + day
    return result;
}

export const formatDatePDF = (date) => {
    let day = date.getDate().toString().length < 2 ? ('0' + date.getDate()) : date.getDate();
    let month = (date.getMonth() + 1).toString().length < 2 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1);
    let year = date.getFullYear();
    let result = year + "-" + month + "-" + day + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    return result;
}

export const formatNumber = (param) => {
    let result = new Intl.NumberFormat().format(param);
    return result;
}