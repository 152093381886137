import { createSlice } from '@reduxjs/toolkit';
import { signIn, signUp, signOut } from './action';
import { ROLE_MEMBER, ROLE_ADMIN } from '../../constants/common.constant';

const initialState = {
    token: '',
    typeToken: '',
    isAuth: false,
    user: {
        id: '',
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        role: [],
    },
    agxMerchantNo: '',
    agxStoreName: '',
    agxStoreEnglishName: '',
    lastSuccessfulLogin:'',
    accountName: '',
    error: null,
    loading: false,
    isAdminOrStore: 0,
    stateSignUp: false,
    statusChangePassword : false
};

export default createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        handleChangeStateSignup: (state) => {
            state.stateSignUp = false;
            state.isAdminOrStore = 9;
        },
        handleClearState: () => initialState,
        handleChangeInfo: (state, action) => {
            state.user.email = action.payload.email;
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
        },
        handleChangeStateChangePassword: (state, action) => {
            state.statusChangePassword = action.payload;
        },
        handleClearError: (state) => {
            state.error= null;
        },
    },
    extraReducers: {
        [signIn.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
        [signIn.fulfilled]: (state, action) => {
            state.loading = false;
            state.token = action.payload.token;
            state.typeToken = action.payload.type;
            state.isAuth = true;
            state.user.id = action.payload.id;
            state.user.username = action.payload.username;
            state.user.email = action.payload.email;
            state.user.firstName = action.payload.firstName;
            state.user.lastName = action.payload.lastName;
            state.user.role = action.payload.roles;
            state.agxMerchantNo = action.payload.agxMerchantNo;
            state.agxStoreName = action.payload.agxStoreName;
            state.agxStoreEnglishName = action.payload.agxStoreEnglishName;
            state.lastSuccessfulLogin = action.payload.lastSuccessfulLogin;
            state.accountName = action.payload.accountName;
            if (action.payload.roles[0] === ROLE_MEMBER) {
                state.isAdminOrStore = action.payload.statusAccount;
            }
            if (action.payload.roles[0] === ROLE_ADMIN || action.payload.roles.length === 0) {
                state.error = 403;
            }
        },
        [signIn.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload? action.payload.status : 500;
        },
        [signUp.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
        [signUp.fulfilled]: (state, action) => {
            state.loading = false;
            // set info user affter signup
            if (action.payload != null) {
                state.token = action.payload.token;
                state.typeToken = action.payload.type;
                state.isAuth = true;
                state.user.id = action.payload.id;
                state.user.username = action.payload.username;
                state.user.email = action.payload.email;
                state.user.firstName = action.payload.firstName;
                state.user.lastName = action.payload.lastName;
                state.user.role = action.payload.roles;
                state.agxMerchantNo = action.payload.agxMerchantNo;
                state.agxStoreName = action.payload.agxStoreName;
                state.agxStoreEnglishName = action.payload.agxStoreEnglishName;
                state.lastSuccessfulLogin = action.payload.lastSuccessfulLogin;
                state.accountName = action.payload.accountName;
                if (action.payload.roles[0] === ROLE_MEMBER) {
                    state.isAdminOrStore = action.payload.statusAccount;
                }
            }
            // set state redirect page
            state.stateSignUp = true;
        },
        [signUp.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.response?.data?.statusCode ? action.payload.response?.data?.statusCode : action.payload.status;
            state.stateSignUp = false;
        },
        [signOut.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
        [signOut.fulfilled]: () => initialState,
        [signOut.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload? action.payload.status : 500;
        },
    },
})
export { signIn, signUp, signOut };