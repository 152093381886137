import { createSlice } from '@reduxjs/toolkit';
import { createMerchant, updateMerchant, getMerchant } from './action';
import { merchantCore } from '../../constants/common.constant';

const initialState = {
    merchant: merchantCore,
    error: null,
    loading: false,
    stateStep: 0,
    statusPage: true,
    merchantNo: null,
    rules: {
        rule_1: false,
        rule_2: false,
        rule_3: false,
        rule_4: false,
    },
    statusUpdate: false
}

export default createSlice({
    name: 'application',
    initialState: initialState,
    reducers: {
        changeStep: (state, action) => {
            if (action.payload.statusPage) {
                state.stateStep = state.stateStep + 1;
            } else {
                state.stateStep = state.stateStep - 1;
            }
        },
        chaneStateMerchant: (state, action) => {
            state.merchant.agxContactId = action.payload.agxContactId;
            state.merchant.agxEmsEmployeeNo = action.payload.agxEmsEmployeeNo;
        },
        chaneStateMerchant1: (state, action) => {
            state.merchant.agxCorporateName = action.payload.merchant.agxCorporateName;
            state.merchant.agxCorporatePhoneticName = action.payload.merchant.agxCorporatePhoneticName;
            state.merchant.agxCorporateEnglishName = action.payload.merchant.agxCorporateEnglishName;
            state.merchant.agxCorporateNumber = action.payload.merchant.agxCorporateNumber;
            state.merchant.agxCorporatePostalCode = action.payload.merchant.agxCorporatePostalCode;
            state.merchant.agxCorporatePrefecture = action.payload.merchant.agxCorporatePrefecture;
            state.merchant.agxCorporatePhoneNumber = action.payload.merchant.agxCorporatePhoneNumber;
            state.merchant.agxCorporateAddress1 = action.payload.merchant.agxCorporateAddress1;
            state.merchant.agxCorporateFaxNumber = action.payload.merchant.agxCorporateFaxNumber;
            state.merchant.agxCorporateAddress1 = action.payload.merchant.agxCorporateAddress1;
            state.merchant.agxCorporateAddress2 = action.payload.merchant.agxCorporateAddress2;
            state.merchant.agxCorporatePhoneticAddress1 = action.payload.merchant.agxCorporatePhoneticAddress1;
            state.merchant.agxCorporatePhoneticAddress2 = action.payload.merchant.agxCorporatePhoneticAddress2;
        },
        chaneStateMerchant2: (state, action) => {
            state.merchant.agxRepresentativeName = action.payload.merchant.agxRepresentativeName;
            state.merchant.agxRepresentativePhoneticName = action.payload.merchant.agxRepresentativePhoneticName;
            state.merchant.agxRepresentativeGender = action.payload.merchant.agxRepresentativeGender;
            state.merchant.agxRepresentativeBirthday = action.payload.merchant.agxRepresentativeBirthday;
            state.merchant.agxRepresentativeAddressCopyFlag = action.payload.merchant.agxRepresentativeAddressCopyFlag;
            state.merchant.agxRepresentativePostalCode = action.payload.merchant.agxRepresentativePostalCode;
            state.merchant.agxRepresentativePrefecture = action.payload.merchant.agxRepresentativePrefecture;
            state.merchant.agxRepresentativeAddress1 = action.payload.merchant.agxRepresentativeAddress1;
            state.merchant.agxRepresentativeAddress2 = action.payload.merchant.agxRepresentativeAddress2;
            state.merchant.agxRepresentativePhoneticAddress1 = action.payload.merchant.agxRepresentativePhoneticAddress1;
            state.merchant.agxRepresentativePhoneticAddress2 = action.payload.merchant.agxRepresentativePhoneticAddress2;
            state.merchant.agxRepresentativePhoneNumber = action.payload.merchant.agxRepresentativePhoneNumber;
            state.merchant.agxRepresentativeFaxNumber = action.payload.merchant.agxRepresentativeFaxNumber;
        },
        chaneStateMerchant3: (state, action) => {
            state.merchant.agxStoreName = action.payload.merchant.agxStoreName;
            state.merchant.agxStorePhoneticName = action.payload.merchant.agxStorePhoneticName;
            state.merchant.agxStoreEnglishName = action.payload.merchant.agxStoreEnglishName;
            state.merchant.agxUrl = action.payload.merchant.agxUrl;
            state.merchant.agxBrandName = action.payload.merchant.agxBrandName;
            state.merchant.agxBusinessDate = action.payload.merchant.agxBusinessDate;
            state.merchant.agxRegularHoliday = action.payload.merchant.agxRegularHoliday;
            state.merchant.agxBusinesssHours = action.payload.merchant.agxBusinesssHours;
            state.merchant.agxStoreAddressCopyFlag1 = action.payload.merchant.agxStoreAddressCopyFlag1;
            state.merchant.agxStoreAddressCopyFlag2 = action.payload.merchant.agxStoreAddressCopyFlag2;
            state.merchant.agxStorePostalCode = action.payload.merchant.agxStorePostalCode;
            state.merchant.agxStorePrefecture = action.payload.merchant.agxStorePrefecture;
            state.merchant.agxStoreAddress1 = action.payload.merchant.agxStoreAddress1;
            state.merchant.agxStoreAddress2 = action.payload.merchant.agxStoreAddress2;
            state.merchant.agxStorePhoneticAddress1 = action.payload.merchant.agxStorePhoneticAddress1;
            state.merchant.agxStorePhoneticAddress2 = action.payload.merchant.agxStorePhoneticAddress2;
            state.merchant.agxStorePhoneNumber = action.payload.merchant.agxStorePhoneNumber;
            state.merchant.agxStoreFaxNumber = action.payload.merchant.agxStoreFaxNumber;
        },
        chaneStateMerchant4: (state, action) => {
            state.merchant.agxBankNo = action.payload.merchant.agxBankNo;
            state.merchant.agxBankName = action.payload.merchant.agxBankName;
            state.merchant.agxBankType = action.payload.merchant.agxBankType;
            state.merchant.agxBankPhoneticName = action.payload.merchant.agxBankPhoneticName;
            state.merchant.agxBranchNo = action.payload.merchant.agxBranchNo;
            state.merchant.agxBranchType = action.payload.merchant.agxBranchType;
            state.merchant.agxBranchName = action.payload.merchant.agxBranchName;
            state.merchant.agxBranchPhoneticName = action.payload.merchant.agxBranchPhoneticName;
            state.merchant.agxAccountType = action.payload.merchant.agxAccountType;
            state.merchant.agxAccountNo = action.payload.merchant.agxAccountNo;
            state.merchant.agxAccountHolder = action.payload.merchant.agxAccountHolder;
        },
        chaneStateMerchant5: (state, action) => {
            state.merchant.agxContactName = action.payload.merchant.agxContactName;
            state.merchant.agxContactPhoneticName = action.payload.merchant.agxContactPhoneticName;
            state.merchant.agxContactEmail = action.payload.merchant.agxContactEmail;
            state.merchant.agxContactPhoneNumber = action.payload.merchant.agxContactPhoneNumber;
            state.merchant.agxCapital = action.payload.merchant.agxCapital;
            state.merchant.agxNumberOfEmployees = action.payload.merchant.agxNumberOfEmployees;
            state.merchant.agxFoundingDate = action.payload.merchant.agxFoundingDate;
            state.merchant.agxMonthlySales = action.payload.merchant.agxMonthlySales;
            state.merchant.agxDoorToDoorSales = action.payload.merchant.agxDoorToDoorSales;
            state.merchant.agxTelemarketingSales = action.payload.merchant.agxTelemarketingSales;
            state.merchant.agxPyramidScheme = action.payload.merchant.agxPyramidScheme;
            state.merchant.agxBusinessOpportunityRelatedSales = action.payload.merchant.agxBusinessOpportunityRelatedSales;
            state.merchant.agxCardInformationRetentionStatus = action.payload.merchant.agxCardInformationRetentionStatus;
            state.merchant.agxSpecifiedContinuousServices = action.payload.merchant.agxSpecifiedContinuousServices;
            state.merchant.agxNoRetainingCardInfoDate = action.payload.merchant.agxNoRetainingCardInfoDate;
            state.merchant.agxPcidssStatus = action.payload.merchant.agxPcidssStatus;
            state.merchant.agxPcidssExpectedComplianceDate = action.payload.merchant.agxPcidssExpectedComplianceDate;
            state.merchant.agxThreeDSecureStatus = action.payload.merchant.agxThreeDSecureStatus;
            state.merchant.agxThreeDSecureDate = action.payload.merchant.agxThreeDSecureDate;
            state.merchant.agxSecurityCodeCheckStatus = action.payload.merchant.agxSecurityCodeCheckStatus;
            state.merchant.agxSecurityCodeCheckDate = action.payload.merchant.agxSecurityCodeCheckDate;
            state.merchant.agxIllegalDeliveryDestinationStatus = action.payload.merchant.agxIllegalDeliveryDestinationStatus;
            state.merchant.agxIllegalDeliveryDestinationDate = action.payload.merchant.agxIllegalDeliveryDestinationDate;
            state.merchant.agxBehaviorAnalysisStatus = action.payload.merchant.agxBehaviorAnalysisStatus;
            state.merchant.agxBehaviorAnalysisDate = action.payload.merchant.agxBehaviorAnalysisDate;
            state.merchant.agxOtherMeasuresStatus = action.payload.merchant.agxOtherMeasuresStatus;
            state.merchant.agxOtherMeasuresDate = action.payload.merchant.agxOtherMeasuresDate;
            state.merchant.agxOtherMeasuresDescription = action.payload.merchant.agxOtherMeasuresDescription;
        },
        setStateRules: (state, action) => {
            state.rules = action.payload;
        },
        changeStatusUpdate: (state, action) => {
            state.statusUpdate = action.payload;
        },
        clearState: () => initialState,
    },
    extraReducers: {
        [createMerchant.pending]: (state) => {
            state.loading = true;
            state.error = null;
        },
        [createMerchant.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.stateStep = 1;
            state.merchantNo = action.payload.data.agxMerchantNo;
        },
        [createMerchant.rejected]: (state, action) => {
            state.loading = false;
            state.error = action?.payload ? action.payload.status : 500;
        },
        [updateMerchant.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.statusUpdate = false;
        },
        [updateMerchant.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.statusUpdate = state.stateStep === 7 ? true : false;
        },
        [updateMerchant.rejected]: (state, action) => {
            state.loading = false;
            state.error = action?.payload ? action.payload.status : 500;
            state.statusUpdate = false;
        },
        [getMerchant.pending]: (state) => {
            state.loading = true;
            state.error = null;
            state.statusUpdate = false;
        },
        [getMerchant.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.merchant = action.payload.data ? action.payload.data : state.merchant;
        },
        [getMerchant.rejected]: (state, action) => {
            state.loading = false;
            state.error = action?.payload ? action.payload.status : 500;
            state.statusUpdate = false;
        },
    }
})

export { createMerchant, updateMerchant, getMerchant };