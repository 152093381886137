import {createAsyncThunk} from '@reduxjs/toolkit'
import { applicationService } from "../../services/index.service";
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


export const createMerchant = createAsyncThunk(
    'application/createMerchant',
    async (payload, thunkAPI) => {
        const merchant = payload;
        try {
            const { data } = await applicationService.create(
                merchant
            );
            Toast.fire({
                icon: 'success',
                title: '作成完了しました。'
            })
            return data;
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: '作成が失敗しました。'
            })
            return thunkAPI.rejectWithValue(error);
        }
    }
)

export const updateMerchant = createAsyncThunk(
    'application/updateMerchant',
    async (payload, thunkAPI) => {
        const merchant = payload?.merchant;
        const agxMerchantNo = payload?.agxMerchantNo;
        const isSendMail = payload?.isSendMail;
        try {
            const { data } = await applicationService.update({
                merchant, agxMerchantNo, isSendMail
            });
            Toast.fire({
                icon: 'success',
                title: '保存が完了しました。'
            })
            return data;
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: '更新が失敗しました。'
            })
            return thunkAPI.rejectWithValue(error);
        }
    }
)


export const getMerchant = createAsyncThunk(
    'application/getMerchant',
    async (payload, thunkAPI) => {
        const agxMerchantNo = payload;
        try {
            const { data } = await applicationService.getData(agxMerchantNo);
            return data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error);
        }
    }
)